<template>
  <div class="pt-2">
    <v-row v-if="filtersReady">
      <component
        v-for="(item, index) in selectedProjectFilters"
        :key="index"
        :is="item"
        :statuses="types"
        :items="status"
        :label="availableProjectFilters[item]"
        v-model="filters[item]"
        @remove="removeFilter(item)"
      ></component>
      <v-col
        :sm="isEmpty(availableFilters) ? 6 : 12"
        :md="isEmpty(availableFilters) ? 4 : 8"
        :lg="isEmpty(availableFilters) ? 2 : 4"
        class="align-self-center"
      >
        <v-menu
          v-if="!isEmpty(availableFilters)"
          top
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              outlined
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <v-icon x-small>mdi-plus</v-icon>
              Add filter
            </v-btn>
          </template>

          <v-list width="250px">
            <v-list-item-group>
              <v-virtual-scroll
                :height="availableFilters.length * 40"
                :itemHeight="40"
                :items="availableFilters"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-title @click="addFilter(item.key)">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn
          x-small
          outlined
          color="grey darken-1"
          dark
          @click="resetFilters"
        >
          reset Filters
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import TextFilter from "../../components/searchParts/TextFilter";
import StatusEnumFilter from "../../components/searchParts/StatusEnumFilter";
import MultiSelectFilter from "../../components/searchParts/MultiSelectFilter";

import { mapGetters, mapState } from "vuex";
import { SET_EMPTY_FILTERS } from "@/store/modules/project/actions";
import { emptyFilters } from "@/store/modules/project/state";
import {
  ADD_PROJECT_FILTER,
  SET_DEFAULT_PROJECT_FILTERS,
  REMOVE_PROJECT_FILTER,
  SET_PROJECT_FILTERS,
} from "@/store/modules/persistentStorage/actions";

export default {
  name: "ProjectSearch",
  components: {
    projectId: TextFilter,
    name: TextFilter,
    account: TextFilter,
    type: StatusEnumFilter,
    status: MultiSelectFilter,
  },
  data: function () {
    return {
      filtersReady: false,
      status: [
        {
          key: "Started",
          item: "started",
        },
        {
          key: "Paused",
          item: "paused",
        },
        {
          key: "Deleted",
          item: "deleted",
        },
        {
          key: "New",
          item: "new",
        },
      ],
      types: [
        {
          key: "Keyword",
          item: "keyword",
        },
        {
          key: "Feed",
          item: "feed",
        },
      ],
    };
  },
  computed: {
    ...mapState("project", ["filters"]),
    ...mapGetters("persistentStorage", [
      "selectedProjectFilters",
      "availableProjectFilters",
    ]),
    availableFilters: function () {
      let vm = this;
      let result = [];

      const filtersToSelect = _.difference(
        _.keys(emptyFilters),
        this.selectedProjectFilters
      );

      filtersToSelect.forEach(function (filter) {
        result.push({
          key: filter,
          label: _.get(vm.availableProjectFilters, filter),
        });
      });
      return result;
    },
  },
  watch: {
    selectedProjectFilters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        // let query = {}
        query["selectedFilters"] = _.merge([], this.selectedProjectFilters);
        query["page"] = this.$route.query.page;

        this.$router.push({ name: this.$route.name, query: {} }).catch((e) => {
          console.log(e);
        });
        this.$router
          .push({ name: this.$route.name, query: query })
          .catch(() => {});
      },
      deep: true,
    },
  },
  methods: {
    addFilter: function (filter) {
      this.$store.dispatch(`persistentStorage/${ADD_PROJECT_FILTER}`, filter);
    },
    removeFilter: function (filter) {
      this.$store.dispatch(`persistentStorage/${REMOVE_PROJECT_FILTER}`, filter);
      this.filters[filter] = null;
    },
    resetFilters: function () {
      this.$store.dispatch(`project/${SET_EMPTY_FILTERS}`);
      this.$store.dispatch(`persistentStorage/${SET_DEFAULT_PROJECT_FILTERS}`);
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
  },
  mounted: function () {
    const vm = this;

    let query = Object.assign({}, this.$route.query);
    if (!_.isEmpty(query) && query["selectedFilters"]) {
      let selectedFilters = query["selectedFilters"];
      if (!(selectedFilters instanceof Array)) {
        selectedFilters = [selectedFilters];
      }
      this.$store.dispatch(
        `persistentStorage/${SET_PROJECT_FILTERS}`,
        selectedFilters
      );
    }
    if (!_.isEmpty(query)) {
      _.forIn(query, function (value, filter) {
        if (_.find(vm.availableFilters, ["key", filter])) {
          vm.addFilter(filter);
        }
      });
    }
    vm.filtersReady = true;
  },
};
</script>
